"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";

import styles from "./headerLogo.module.css";

export function HeaderLogo() {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => setIsMounted(true), []);
  if (!isMounted) return null;

  return (
    <div className={styles.left}>
      <Image
        alt="menu"
        width={32}
        height={32}
        src="/icons/menu.svg"
        className={styles.menu_icon}
        onClick={() => setIsMobileMenuVisible(true)}
        // onError={(e) => {
        //   e.currentTarget.onerror = null; // Prevent infinite loop
        //   e.currentTarget.src = "https://example.com/default.png"; // Fallback image
        // }}
      />
      {isMobileMenuVisible && (
        <nav className={styles.mobile_nav}>
          <Image
            alt="close"
            width={32}
            height={32}
            src="/icons/close.svg"
            className={styles.close_icon}
            onClick={() => setIsMobileMenuVisible(false)}
            // onError={(e) => {
            //   e.currentTarget.onerror = null; // Prevent infinite loop
            //   e.currentTarget.src = "https://example.com/default.png"; // Fallback image
            // }}
          />
          <Link href={"/"}>Home</Link>
          <Link href={"/about"}>About</Link>
          <Link href={"/contact"}>Contact</Link>
        </nav>
      )}
      <Link href={"/"} className={`${styles.logo} ${styles.light}`}>
        <Image
          alt="logo"
          width={160}
          height={60}
          src="/icons/logo/logo.png"
          onError={(e) => {
            e.currentTarget.onerror = null; // Prevent infinite loop
            e.currentTarget.src = "https://example.com/default.png"; // Fallback image
          }}
        />
      </Link>
    </div>
  );
}
