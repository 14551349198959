"use client";
import React, { useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useTokenStore } from "@/app/_store/token";
import useUserData from "@/app/_hooks/userData";

import styles from "./headerProfile.module.css";

export function HeaderProfile() {
  const router = useRouter();
  const token = useTokenStore((state) => state.token);
  const setToken = useTokenStore((state) => state.setToken);

  const handleUnauthorized = () => {
    setToken("");
    router.push("/");
  };

  const { data: userData, isLoading, isError } = useUserData(handleUnauthorized);

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => setIsMounted(true), []);
  if (!isMounted) return null;

  return (
    <Link href={"/account"}>
      {token ? (
        <div className={styles.header_profile}>
          <span className="!font-mono font-medium uppercase">
            {isLoading ? "Loading" : isError ? "Error" : userData?.username}
          </span>
          <Image
            alt="User"
            width={33}
            height={33}
            src={userData?.logoUrl?.trim() ? userData.logoUrl : "/icons/user.svg"}
            onError={(e) => ((e.currentTarget.onerror = null), (e.currentTarget.src = "/icons/user.svg"))}
          />
        </div>
      ) : (
        <div className={styles.header_login}>LOGIN / SIGNUP</div>
      )}
    </Link>
  );
}
